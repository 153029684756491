const urls = [
  {
    url: process.env.REACT_APP_IRON_RODS_BACKEND_URL,
    name: "Iron Rods",
    port: "6002",
    isStore: true,
    isHq: true,
    isSelected: true
  },
  {
    url: process.env.REACT_APP_IRON_RODS_AKURE_BACKEND_URL,
    name: "Iron Rods - Akure",
    port: "7000",
    // viewOnly: true,
  },
  {
    url: process.env.REACT_APP_CEMENT_BACKEND_URL,
    name: "Dangote Cement",
    port: "6002",
    // viewOnly: true,
  },
  {
    url: process.env.REACT_APP_CEMENT_BUA_BACKEND_URL,
    name: "Bua Cement",
    port: "6002",
  },
  //  {
  //   url: "https://gigc-rod-test-api.invexonline.com",
  //   name: "Iron Rods - Test",
  //   port: "6106",
  //   // viewOnly: true,
  // },
];
export default urls;
